import { Alert, Box, Button, ButtonDropdown, Form, FormField, Header, Input, Modal, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { useState } from "react";
import getAuthenticated, { postAuthenticated } from "../request";
import { useErrorContext } from "../ErrorContext";
import { Entitlement, EntitlementsFromJson } from "../interfaces/Entitlement";
import EntitlementsTable from "./FortitudeGrantsTable";
import { useOktaAuth } from '@okta/okta-react';
import entitlementTypes from '../entitlementTypes.json';

interface Validation {
    reason?: string;
    amount?: string;
}

export default function GrantFortitude({ contextUsername }: { contextUsername?: string }) {
    const [username, setUsername] = useState( contextUsername || "")
    const [reason, setReason] = useState("")
    const [amount, setAmount] = useState(0)
    const [type, setType] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [grants, setGrants] = useState<Entitlement[] | null>(null)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [validationError, setValidationError] = useState<Validation | null>(null)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()
    const [confirmationVisible, setConfirmationVisible] = useState(false)

    return <>
        {successMessage && <Alert type="success" dismissible onDismiss={() => setSuccessMessage("")}>{successMessage}</Alert>}
        <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<SpaceBetween direction="horizontal" size="s">
                    <Button disabled={loading || username.length === 0} onClick={() => {
                        setLoading(true)
                        getAuthenticated(`/api/entitlements/list`, auth, { params: { username } }).then(res => {
                            if(res) {
                                setGrants(EntitlementsFromJson(res.data))
                                setLoading(false)
                            }
                        }, err => {
                            setError(err?.response?.data)
                            setLoading(false)
                        })
                    }}>Fetch Previous Entitlements</Button>
                    <Button variant="primary" disabled={loading || username?.length === 0 || reason?.length === 0} onClick={() => {
                        setValidationError(null)
                        if(reason.length > 100) {
                            setValidationError({reason: "Reason must be less than 100 characters"})
                            return
                        }
                        if(type < 0) {
                            setValidationError({reason: "Entitlement type must be selected"})
                            return
                        }
                        if(!entitlementTypes.some(e => e.id === `${type}`)) {
                            setValidationError({reason: "Invalid entitlement type"})
                            return
                        }
                        if(username.length > 0 && reason.length > 0) {
                            setConfirmationVisible(true)
                        }
                    }}>Grant entitlement</Button>
                    </SpaceBetween>}
                >
                    <SpaceBetween direction="vertical" size="s">
                        <FormField label="Username">
                            <Input
                                onChange={({ detail }) => setUsername(detail.value)}
                                value={username}
                                placeholder={"Account Username"}
                                autoFocus
                                type="search"
                                disabled={contextUsername != null}
                            />
                        </FormField>
                        <FormField>
                            <ButtonDropdown
                                items={entitlementTypes}
                                onItemClick={({ detail }) => {
                                    setType(parseInt(detail.id))
                                }}
                            >
                                Entitlement Type: {entitlementTypes.find(e => e.id === `${type}`)?.text}
                            </ButtonDropdown>
                        </FormField>
                        <FormField label="Reason" errorText={validationError?.reason}>
                            <Textarea
                                onChange={({ detail }) => setReason(detail.value)}
                                value={reason}
                                placeholder={"Reason (Shown in claim window)"}
                            />
                        </FormField>
                        <FormField label="Amount" errorText={validationError?.amount}>
                            <Input
                                onChange={({ detail }) => setAmount(parseInt(detail.value))}
                                value={`${amount}`}
                                placeholder={"Amount (if applicable)"}
                                type="number"
                            />
                        </FormField>
                    </SpaceBetween>
            </Form>
        </form>
        { loading 
            ? <Spinner size="large"/>
            : <SpaceBetween direction="vertical" size="l">
                <Header variant="h2">Previous Entitlements</Header>
                {grants != null && <EntitlementsTable grants={grants} loading={loading} />} 
            </SpaceBetween>
        }
        <Modal
            onDismiss={() => setConfirmationVisible(false)}
            visible={confirmationVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => { setConfirmationVisible(false) }}>Cancel</Button>
                        <Button variant="primary"
                            onClick={() => {
                                setLoading(true)
                                setConfirmationVisible(false)
                                postAuthenticated(`/api/entitlements/create`, {
                                        username,
                                        quantity: amount,
                                        type,
                                        reason
                                    }, auth).then(res => {
                                        getAuthenticated(`/api/entitlements/list`, auth, { params: { username } }).then(res => {
                                            if(res) {
                                                setGrants(EntitlementsFromJson(res.data))
                                                setLoading(false)
                                            }
                                        }, err => {
                                            setError(err?.response?.data)
                                            setLoading(false)
                                        })
                                    setSuccessMessage(`Grant to ${username} Successful`)
                                }, err => {
                                    setError(err?.response?.data)
                                    setLoading(false)
                                })
                            }}
                        >Yes, grant {entitlementTypes.find(e => e.id === `${type}`)?.text} </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Confirm Entitlement Grant"
        >
            Are you sure you want to grant {amount > 0 ? amount : ''} <strong>{entitlementTypes.find(e => e.id === `${type}`)?.text}</strong> to <strong>{username}</strong> with the reason "{reason}"?
        </Modal>
    </>
}
