import { useEffect, useState } from "react"
import { SupportTicket, SupportTicketsFromJson } from "../interfaces/Account"
import { Command } from "../interfaces/Baselining"
import { useOktaAuth } from "@okta/okta-react"
import getAuthenticated, { postAuthenticated } from "../request"
import { Box, Button, Form, FormField, Header, Input, Link, Modal, Pagination, SpaceBetween, Table } from "@cloudscape-design/components"
import { useErrorContext } from "../ErrorContext"

export default function Baselining() {
    const [loading, setLoading] = useState(true)
    const [commands, setCommands] = useState<Command[]>([])
    const [tickets, setTickets] = useState<SupportTicket[]>([])
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    useEffect(() => {
        getAuthenticated("/api/baselining/unresolved-commands", auth).then(res => {
            setCommands(res.data as Command[])
            setLoading(false)
        })
        getAuthenticated("/api/baselining/support-tickets", auth).then(res => {
            setTickets(SupportTicketsFromJson(res.data))    
        })
    }, [])

    return <>
        <CommandTable commands={commands} loading={loading} tickets={tickets} submit={() => {
            setLoading(true)
            const runs = commands
                .filter(c => c.comment || c.ticket_id)
                .map(c => postAuthenticated("/api/baselining/command", c, auth))

            Promise.all(runs).then(() => {
                setLoading(false)
                getAuthenticated("/api/baselining/unresolved-commands", auth).then(res => {
                    setCommands(res.data as Command[])
                })
            }, err => {
                setLoading(false)
                setError(err)
            })
        }}/>
    </>
}

const columnDefinitions = [
    {id: 'timestamp', header: 'Timestamp', cell: (row: Command) => <div>{new Date(row.logTimestamp).toLocaleString()}</div>},
    {id: 'actor', header: 'Actor', cell: (row: Command) => <div>{row.actorName}</div>},
    {id: 'command', header: 'Command', cell: (row: Command) => <div>{row.command}</div>},
    {id: 'message', header: 'Message', cell: (row: Command) => <div>{row.message}</div>},
    {id: 'ticket', header: 'Ticket', cell: (row: Command) => row.ticket_id ? <Link href={`https://swgr.org/support/${row.ticket_id}`} target="_blank">{row.ticket_id}</Link> : <></>},
    {id: 'comment', header: 'Comment', cell: (row: Command) => <div>{row.comment}</div>},
]

function CommandTable({commands, tickets, loading, submit}: {commands: Command[], tickets: SupportTicket[], loading: boolean, submit: () => void}) {
    const [selectedCommands, setSelectedCommands] = useState<Command[]>([])
    const [ticketModalOpen, setTicketModalOpen] = useState(false)
    const [commentModalOpen, setCommentModalOpen] = useState(false)
    const [comment, setComment] = useState("")

    return <>
        <Table
            columnDefinitions={columnDefinitions}
            items={commands}
            loading={loading}
            loadingText="Loading Commands"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>None Found</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p" 
                        color="inherit"
                    >
                        No data to display.
                    </Box>
                </Box>
            }
            header={
                <Header
                    description="Commands that have not been assigned a support ticket or a justification."
                    actions={<SpaceBetween direction="horizontal" size="m">
                        <Button disabled={selectedCommands.length === 0} onClick={() => setTicketModalOpen(true)}>Assign to Support Ticket</Button>
                        <Button disabled={selectedCommands.length === 0} onClick={() => setCommentModalOpen(true)}>Add Comment</Button>
                        <Button disabled={commands.every(c => !c.ticket_id && !c.comment)} onClick={() => {
                            submit()
                        }}>Submit</Button>
                    </SpaceBetween>}
                    >Unresolved Commands</Header>
            }
            selectionType="multi"
            onSelectionChange={(selection) => {
                setSelectedCommands(selection.detail.selectedItems)
            }}
            selectedItems={selectedCommands}
        />
        <Modal
            size="max"
            visible={ticketModalOpen}
            onDismiss={() => { setTicketModalOpen(false) } }
            header="Assign to Support Ticket"
            footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => setTicketModalOpen(false)} variant="link">Cancel</Button>
                  </SpaceBetween>
                </Box>
            }
            >
                <SupportTickets tickets={tickets} selectCallback={(ticket) => {
                    selectedCommands.forEach(command => {
                        command.ticket_id = ticket.ticket_id
                    })
                    setTicketModalOpen(false)
                    setSelectedCommands([])
                }}/>
            </Modal>
        <Modal
            visible={commentModalOpen}
            onDismiss={() => { setCommentModalOpen(false); setComment("")} }
            header="Add Comment"
            footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => setCommentModalOpen(false)} variant="link">Cancel</Button>
                  </SpaceBetween>
                </Box>
            }
            >
                <Form actions={[
                    <Button formAction="submit" variant="primary" onClick={()=> {
                        selectedCommands.forEach(command => {
                            command.comment = comment
                        })
                        setCommentModalOpen(false)
                        setComment("")
                        setSelectedCommands([])
                    }}>Submit</Button>
                ]}>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Comment"><Input value={comment} onChange={event => setComment(event.detail.value)} /></FormField>
                    </SpaceBetween>
                </Form>
            </Modal>
    </>
}


function SupportTickets({tickets, selectCallback}: {tickets: SupportTicket[], selectCallback: (ticket: SupportTicket) => void}) {
    const [page, setPage] = useState(0)
    const pageSize = 10

    const pagination = <Pagination
        currentPageIndex={page}
        pagesCount={Math.ceil(tickets.length / pageSize)}
        onChange={({detail}) => {
            setPage(detail.currentPageIndex)
        }}
    />

    const sortedTickets = tickets.sort((a: SupportTicket, b: SupportTicket) => (b.start_date?.getTime() || 0) - (a.start_date?.getTime() || 0))
    const pageTickets = sortedTickets.slice(page * pageSize, (page + 1) * pageSize)

    return <Table 
        pagination={pagination}
        columnDefinitions={[
            {id: 'select', header: '', cell: (row: SupportTicket) => <Button onClick={() => selectCallback(row)}>Select</Button>},
            {id: 'ticket_id', header: 'Ticket ID', cell: (row: SupportTicket) => <div>{row.ticket_id}</div>},
            {id: 'start_date', header: 'Start Date', cell: (row: SupportTicket) => <div>{row.start_date?.toLocaleString() || ''}</div>},
            {id: 'username', header: 'Username', cell: (row: SupportTicket) => <div>{row.username}</div>},
            {id: 'title', header: 'Title', cell: (row: SupportTicket) => <div>{row.title}</div>},
            {id: 'status', header: 'Ticket Status', cell: (row: SupportTicket) => <div>{row.status}</div>},
            {id: 'ticket_ref', header: 'Ticket Ref', cell: (row: SupportTicket) => <div>{row.ticket_ref}</div>},
            {id: 'link', header: 'Link', cell: (row: SupportTicket) => <Link href={`https://swgr.org/support/${row.ticket_id}`} target="_blank">View Ticket</Link>}
        ]}
        items={pageTickets}
        empty="No Support Tickets Found"
    />
}